<template>
  <div>
     <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Resturant") }}</label>
      <span>/</span>
      <label active>{{$t('CusineMenu')}}</label>

    </div>
    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->


   <v-toolbar flat>
          <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog
            :width="config.modalFormWidth"
            @click:outside="closeModal"
          >
            <template v-slot:activator="{ on, attrs }">
              <div>
                <v-btn
                   v-if="
                user &&
                user.permissions &&
                user.permissions.includes('store_menu')
              "
                  color="info"
                  @click="clearData"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <span class="addNewLabel">{{ $t("AddNew") }}</span>

                  <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
                </v-btn>
                <download-excel
                  class="
                    mb-2
                    mx-5
                    cursorPointer
                    v-btn
                    theme--dark
                    v-size--small
                    success
                    v-btn v-btn--is-elevated v-btn--has-bg
                  "
                  :data="table.items"
                  :fields="table.itemExport"
                  type="xls"
                  :header="$t(config.tableName)"
                  :worksheet="$t(config.tableName)"
                  :name="$t(config.tableName)"
                >
                  <span class="excelLabel">{{ $t("ExportExcel") }}</span>
                  <v-icon
                    class="my-0 mr-2 ml-2"
                    style="color: #fff !important"
                    dense
                    >mdi-export</v-icon
                  >
                </download-excel>

                <v-btn
                  color="danger"
                  @click="loadPDF"
                  dark
                  small
                  class="mb-2 mr-2 ml-2"
                >
                  <span class="pdfLabel"> {{ $t("LoadPDF") }} </span>
                  <v-icon class="my-0 mr-2 ml-2" dense>mdi-eye</v-icon>
                </v-btn>
              </div>
            </template>

          </v-dialog>
        </v-toolbar>

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.status="{ item }">
        <p
          @click="updateStatus(item)"
          v-if="item.status"
          class="my-0 clr-success clickable"
        >
          {{ $t("Active") }}
        </p>
        <p @click="updateStatus(item)" v-else class="my-0 clr-danger clickable">
          {{ $t("InActive") }}
        </p>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
         v-if="
                user &&
                user.permissions &&
                user.permissions.includes('update_menu')
              "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            $router.push({name: 'menuaddEdit',params: {id: item.id}})
          "
          color="success"
          >mdi-pencil</v-icon
        >

        <v-icon
           v-if="
                user &&
                user.permissions &&
                user.permissions.includes('delete_menu')
              "
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
export default {
  name: "menus",
  data() {
    return {
             user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "MenuesTable",
        crudHeader: "Menues",
        crudApi: "menu",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "تاريخ الطبخ": {
            field: "cooking_date",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("CookingDate"),
            value: "cooking_date",
            width: "10rem",
          },
        ],
      },
      filters: {
        name: null,
        status: null,
      },
      formData: {
        id: 0,
        cooking_date: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      this.getDataFromApi();
    },
    clearData() {
      this.formData = {
        cooking_date: null,
      }
        this.$router.push({name: 'menuaddEdit'})
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
      this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator
        .validateAll("addEditValidation")
        .then((valid) => {
          if (!valid)
            return this.notifyCustomError("Error", "ValidationsErrors");
          this.saveUpdateData(
            `${this.config.crudApi}/saveData`,
            `${this.config.crudApi}/updateData`,
            this.formData
          ).then(() => {
            this.getDataFromApi();
            this.closeModal();
          });
        });
    },
    getDataFromApi(options) {
      this.table.loading = true;
      this.getFrontEndData(
        `${this.config.crudApi}/getData`,
        options,
        this.filters
      )
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(res.pagination.total / res.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

       if (
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("update_menu")) ||
      (this.user &&
        this.user.permissions &&
        this.user.permissions.includes("delete_menu"))
    )
      this.table.headers.push({
        text: this.$t("Actions"),
        value: "actions",
        sortable: false,
        align: "center",
        width: "8rem",
      });
  },
};
</script>